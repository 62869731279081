import axios from "axios";
import { showToaster } from "src/redux/slice/toasterSlice";
import { closeLoader } from "src/redux/slice/loaderSlice";
import { API_GET_LEAD, BASE_URL } from "../constants/apiUrls";

const ignoreURL = [API_GET_LEAD];

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // timeout: 8000,
  headers: { "content-type": "application/json", Accept: "application/json" },
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

axiosInstance.interceptors.request.use(
  (request) => {
    // Do something before request is sent'

    const token = localStorage.getItem("token");
    request.headers.Authorization = token ? `Bearer ${token}` : null;
    return request;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(closeLoader());
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    const errMsg = error.response?.data?.statusMessage;
    window.console.log(errMsg);
    if (!ignoreURL.includes(error?.config?.url)) {
      store.dispatch(
        showToaster({ type: "error", text: errMsg || "Something went wrong" })
      );
    }
    store.dispatch(closeLoader());
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status && error?.response?.status === 401) {
      const requestSentData = localStorage.getItem("requestSentData");
      localStorage.clear(); 
    
      if (requestSentData) {
        localStorage.setItem("requestSentData", requestSentData); 
      }
      window.location("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
