import avatar from "src/assets/images/avatar_color.svg";
import moment from "moment/moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import { exportToExcel } from 'react-json-to-excel';

// eslint-disable-next-line import/prefer-default-export
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      // eslint-disable-next-line no-param-reassign
      file.name = "cropped.jpg";
      resolve({ file, url: URL.createObjectURL(file) });
    }, "image/jpeg");
  });
}

export const dateFormatter = (dateString) => {
  return moment(dateString).format("DD MMM YYYY");
};
export const dateFormatterWithDash = (dateString) => {
  return moment(dateString).format("DD-MMM-YYYY");
};

export const dateFormatterObject = (dateString) => {
  const currentDate = moment(dateString);
  return {
    date: currentDate.date(),
    month: currentDate.month() + 1,
    year: currentDate.year(),
  };
};

const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export function getDayNumber(day) {
  const index = days.indexOf(day.toLowerCase());
  return index !== -1 ? index : "Invalid day";
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getMonthNumber(monthName) {
  return monthNames.indexOf(monthName) + 1;
}

export function roundToNearest30Minutes(dateTime) {
  const minutes = dateTime.minutes();
  dateTime
    .minutes(Math.round(minutes / 30) * 30)
    .seconds(0)
    .milliseconds(0);
  return dateTime;
}

export function add30Minutes(time) {
  const momentTime = moment(time, "HH:mm");
  momentTime.add(30, "minutes");
  return momentTime.format("HH:mm");
}

export const dataFormatorForCard = (dateString) => {
  return `${moment(dateString).format("DD MMM YYYY")},  ${moment(dateString).fromNow()}`;
};

export const dateFromNow = (dateString) => {
  const diff = moment(new Date()).diff(moment(dateString), "hours");
  if (diff > 24) {
    return dateFormatter(dateString);
  }
  return moment(dateString).fromNow();
};

export const getM2sqre = (feet) => {
  if (feet && feet !== "NaN") {
    return (feet * 0.092903).toFixed(2);
  }
  return 0;
};

export function debounce(func, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export function stringAvatar(name, show) {
  let d = {};
  if (!show) {
    d.src = avatar;
  } else {
    d = {
      sx: {
        bgcolor: "#D5E8D4",
      },
      children: `${name.charAt(0).toUpperCase()}`,
    };
  }
  return d;
}

export const generateTimeArray = () => {
  const timeArray = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2) % 12 || 12;

    const minute = i % 2 === 0 ? "00" : "30";
    const ampm = i < 24 ? "AM" : "PM";
    const hour24 = Math.floor(i / 2) < 10 ? `0${Math.floor(i / 2)}` : Math.floor(i / 2);

    return {
      label: `${hour}:${minute} ${ampm}`,
      value: `${hour24}:${minute}`,
    };
  });

  return timeArray;
};

export const getUserId = () => localStorage.getItem("userId");
export const getToken = () => localStorage.getItem("token");
export const getProfileType = () => localStorage.getItem("profileType");
export const getNumOfDays = (startDate, endDate) => moment(endDate).diff(moment(startDate), "days");

export const changeAllOccurenceOfDate = (
  startDate,
  actualStartDate,
  data,
  dataKey = "tasks",
  key1 = "startDate",
  key2 = "endDate"
) => {
  return data.map((item) => {
    const updatedData = item[dataKey].map((dataItem) => {
      const diffDate = moment(actualStartDate).diff(moment(startDate), "days");
      const stDate = moment(dataItem[[key1]]);
      const endDate = moment(dataItem[[key2]]);
      const duration = endDate.diff(stDate, "days");

      const updatedStDate = moment(stDate).add(diffDate, "days").format();
      const updatedEndDate = moment(updatedStDate).add(duration, "days").format();

      return { ...dataItem, [key1]: updatedStDate, [key2]: updatedEndDate };
    });
    return { ...item, [dataKey]: updatedData };
  });
};

export const capitalizeWord = (str) =>
  str
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

// helper function to construct option ex : {value: 'value', label: 'value lable'}
export const mapOptions = (arr) =>
  arr.map((ele) => Object({ value: ele, label: capitalizeWord(ele) }));

export const arrayToEnum = (arr) => arr.reduce((obj, value) => {
  obj[value] = value;
  return obj;
}, {});

export const getFileName = (path) => path?.split('/').pop();

export const sortByDate = (rawArray, sortby) => {
  const _rawArray = [...rawArray];
  return _rawArray.sort((left, right) =>
    moment.utc(left[new Date(sortby)]).diff(moment.utc(right[new Date(sortby)]))
  );
};

export const toMegabytes = (bytes) => {
  const megabytes = `${(bytes / 1048576).toFixed(2)} Mb`;
  return megabytes
}
export const copyToClipBoard = (text) => navigator.clipboard.writeText(text);
export const isCurrentMonth = (monthNumber, year) => {
  const currentDate = moment();
  const targetDate = moment({ year, month: monthNumber - 1 });

  return currentDate.isSame(targetDate, "month");
};

export const isDateInRange = (startDate, endDate) => {
  const currentDate = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return currentDate.isBetween(start, end, null, "[]");
};

export const generateSubSection = (currentYear, currentMonth, type) => {
  const monthStart = moment({ year: currentYear, month: currentMonth });
  const monthEnd = moment(monthStart).endOf("month");

  if (type === "day") {
    return Array.from({ length: monthEnd.date() }, (_, i) => {
      const date = moment(monthStart).add(i, "days").format("YYYY-MM-DD");
      return { day: moment(date).format("DD"), date };
    });
  }
  if (type === "week") {
    const weeksInMonth = Math.ceil(monthEnd.date() / 7);
    return Array.from({ length: weeksInMonth }, (_, i) => {
      const startOfWeek = moment(monthStart).add(i * 7, "days");
      const endOfWeek = moment(startOfWeek).add(6, "days");
      const weekStart = startOfWeek.format("YYYY-MM-DD");
      const weekEnd = endOfWeek.isBefore(monthEnd)
        ? endOfWeek.format("YYYY-MM-DD")
        : monthEnd.format("YYYY-MM-DD");
      return { day: `w${i + 1}`, date: `${weekStart} - ${weekEnd}` };
    });
  }

  return [];
};
export const getMonthsAndSubSectionInRange = (startDate, endDate, type = "day") => {
  const months = [];

  let currentMonth = startDate.month();
  let currentYear = startDate.year();

  while (
    currentYear < endDate.year() ||
    (currentYear === endDate.year() && currentMonth <= endDate.month())
  ) {
    const month = {
      name: moment({ year: currentYear, month: currentMonth }).format("MMM"),
      year: currentYear,
      monthNumber: currentMonth + 1,
      subSection: generateSubSection(currentYear, currentMonth, type),
    };

    months.push(month);

    currentMonth += 1;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear += 1;
    }
  }

  return months;
};


export const onTemplateExport = (taskDetails) => {
  const completeTasks = taskDetails?.map(category => category.tasks).flat();
  const materialsR = completeTasks?.map(task => task.materialRequired ).flat();

  const _taskDetails = completeTasks?.map(task => ({ 'Sl': task.templateTaskNo || task._id, 'Task Category': task.category, 'Task Name': task.name
  , 'Assigned Deptt': task.tags?.join(','), 'Duration(days)': task.duration, 'St Date': moment(task.startDate).format("DD/MM/YYYY") , 'End date': moment(task.endDate).format("DD/MM/YYYY"), 'Finish To Start': (task.dependency[0] && task.dependency[0].type === 'finish_to_start') ? `${task.dependency[0].task}${task.dependency[0].delay > 0 ? `+${task.dependency[0].delay}` : ''}` : '', 'Start to Start': (task.dependency[0] && task.dependency[0].type === 'start_to_start') ? `${task.dependency[0].task}${task.dependency[0].delay > 0 ? `+${task.dependency[0].delay}` : ''}` : '', 'Checklist': task.checklist.map(check => check.name)?.join(',') }));

  const materialDetails = materialsR?.map(mat => ({ taskId: mat?.task, name: mat?.name, unit: mat?.unit, quantity: mat?.quantity, type: mat?.type, 'brand': mat?.brand }));

  const sampleJson = [{
      sheetName:"Task Details",
      details: _taskDetails
    },{
      sheetName:"Material Required",
      details: materialDetails
    }];

  exportToExcel(sampleJson, 'template', true )
}
