/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from '@mdi/react';
import { mdiTrayArrowUp, mdiPencilOutline } from '@mdi/js';
import uploadFile from "../../../services/fileUpload.service";
import userImg from '../../../assets/images/person.jpg';
import { updateUserById, userSelector } from "../../../redux/slice/userSlice";
import styles from './profilepic.module.scss';

// eslint-disable-next-line no-unused-vars
const ProfilePic = ({src, isUpload=false, size , isProfile=false, noPadding=false}) => {
    const userId = localStorage.getItem('userId');
    const user = useSelector(userSelector);
    const profileMeta = user?.profileMeta;
    const dispatch = useDispatch();
    const fileRef = useRef();

    const onUpload = () => {
        fileRef.current.click()
    }
    const onFileChange = event => {
        const file = event.target.files[0];
        if(file) {
            const reqObjj = {files: file}
            uploadFile(reqObjj, 'users').then(res => {
                console.log("KKKKKKKKKKKKKKKKKKKKKKKK")
                const resObj = res.data;
                const reqObj = {userId, data: {profileMeta: {...profileMeta,profilePicture: resObj.response.locationArr[0]}}}
                dispatch(updateUserById(reqObj))
            });
        }
    };
    
    return (
        <div className={`${styles.profilepic_container} ${noPadding ? styles.no_padding : ''}`} style={{ padding: size === 'sm' && 0}}>
            <div className={`${styles.profilepic} ${styles[size]} ${!src && styles.no_border}`}>
                {src ?  
                <img src={src} alt="Profile" className="img-fluid" />
                :
                
                    isProfile ?  <img src={userImg} alt="Profile" className="img-fluid" /> :
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div onClick={onUpload} className={`${styles.placeholder} d-flex flex-column`}>
                        <input ref={fileRef} type="file" onChange={onFileChange} accept="image/*" style={{ display: 'none' }} id="contained-button-file"/>
                        <Icon path={mdiTrayArrowUp} size={4} color='#546C5F' />
                        <h6>Upload</h6>
                    </div>
                }
                
            </div>
                {isUpload && 
                    <>
                        <input ref={fileRef} type="file" onChange={onFileChange} accept="image/*" style={{ display: 'none' }} id="contained-button-file"/>
                        <button className={styles.upload_btn} onClick={onUpload} type="button"  aria-label="Edit Profile Picture"
                         htmlFor="contained-button-file">
                            <Icon path={mdiPencilOutline} size={1} color='#000' />
                        </button>
                    </>
                }
        </div>
    )
};

export default ProfilePic;